


(function(jQuery) {
    $(document).ready(function() {
      $( ".main-nav-bar ul" ).clone().appendTo( ".side-panel-in" ).removeClass('main-menu').addClass('sm-menu');
      $( ".toggle-btn" ).on( "click", function() {
        $('.side-panel-nav').toggleClass('is-active')
      });

      $( ".close-panel-btn" ).on( "click", function() {
        $('.side-panel-nav').removeClass('is-active');
      });

    
    });
   $(window).on('load', function () {
  });


  $(window).on('resize', function() {

});


  $(window).scroll(function(){
    if ($(window).scrollTop() >= 40) {
      $('.theme-header').addClass('fixed-is-active');
     }
     else {
      $('.theme-header').removeClass('fixed-is-active');
     }
  });

})(jQuery);
